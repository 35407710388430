/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import _ from 'lodash'

import { PostFormat } from 'sharedKernel/api'
import { regexPatterns } from 'sharedKernel/regex'
import { IRegisterFormValues } from '../store'

export const createRegisterRequest = (form: IRegisterFormValues, url: string, acceptUserTerms: boolean): PostFormat => {
  const request: PostFormat = {
    url,
    data: {}
  }

  request.data = transformFormDataToPostObject(form, acceptUserTerms)
  return request
}

const transformFormDataToPostObject = (form: IRegisterFormValues, acceptUserTerms: boolean): any => {
  const cleanObject = Object.fromEntries(
    Object.entries(form).map(([key, value]) => [key, value.value])
  )

  const data = {
    name: cleanObject.startupName,
    contactName: cleanObject.contactName,
    contact: {
      mobile: cleanObject.phone.replace(regexPatterns.clearMaskedNumbers, '')
    },
    email: cleanObject.email,
    cnpj: cleanCNPJ(cleanObject.cnpj),
    website: cleanObject.startupWebsite,
    password: cleanObject.password,
    confirmPassword: cleanObject.confirmPassword,
    address: getAddress(cleanObject),
    country: cleanObject.country,
    isUserTermsAccepted: acceptUserTerms,
    activity: cleanObject.activityOther?.trim() ? cleanObject.activityOther : cleanObject.activity,
    startupInfo: {
      businessModel: cleanObject.businessModel,
      numberEmployees: cleanObject.numberEmployees,
      technologies: cleanObject.technologies,
      developmentStage: cleanObject.developmentStage?.trim() ? transformIntNumber(cleanObject.developmentStage) : null,
      annualTurnover: transformFloatNumber(cleanObject.annualTurnover),
      hasRaisedInvestment: Boolean(cleanObject.hasRaisedInvestment),
      amount: transformFloatNumber(cleanObject.amount),
      investmentSource: cleanObject.otherInvestmentSource?.trim() ? cleanObject.otherInvestmentSource : cleanObject.investmentSource,
      pitch: cleanObject.pitch
    }
  }

  return { data }
}
const transformFloatNumber = (value: string): number => {
  return value ? parseFloat(value) : 0.0
}

const transformIntNumber = (value: string): number => {
  return value ? parseInt(value) : 0
}
const getAddress = (cleanObject: any): any => {
  cleanObject.zipcode = cleanObject.zipcode.replace(regexPatterns.clearMaskedNumbers, '')

  return _.pick(cleanObject, ['zipcode', 'lineOne', 'number', 'lineTwo', 'city', 'state'])
}

const cleanCNPJ = (cnpj: string): string => {
  if (cnpj == null) {
    return ''
  }
  return cnpj.replace(/[^\d]/g, '')
}
