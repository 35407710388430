import { BuildInput } from './input'
import { BuildDynamicField } from './dynamic'
import { IDatePicker, IDynamicField, IField, IFile, IMultiFile, ITypeahead } from '../../constants'
import { BuildFile } from './file'
import { BuildDatePicker } from './datePicker'
import { BuildSelect } from './select'
import { BuildMultiFile } from './multiFile'
import { BuildTypeahead } from './typeahead'
import { BuildCheckbox } from './checkbox'

export function BuildField(
  item: IField | IDatePicker | IDynamicField | IFile | IMultiFile | ITypeahead,
  key: string | number
): any {
  switch (item.type) {
    case 'dynamic':
      return BuildDynamicField(item as IDynamicField, key)
    case 'datePicker': {
      return BuildDatePicker(item as IDatePicker, key)
    }
    case 'uploadImage':
      return BuildFile(
        {
          ...(item as IFile),
          acceptedFile: 'image/*'
        },
        key
      )
    case 'multiFile':
      return BuildMultiFile(
        {
          ...(item as IMultiFile),
          acceptedFile: '**'
        },
        key
      )
    case 'uploadVideo':
      return BuildFile(
        {
          ...(item as IFile),
          acceptedFile: 'video/*'
        },
        key
      )
    case 'select':
      return BuildSelect(item as IField, key)
    case 'typeahead':
      return BuildTypeahead(item as ITypeahead, key)
    case 'checkbox':
      return BuildCheckbox(item as IField, key)
    case 'texteditor':
    case 'text':
    case 'textarea':
    case 'youtubeLink':
    case 'youtubeLinkTitle':
    default:
      return BuildInput(item as IField, key)
  }
}
