// #region Imports
import React, { useEffect, useState } from 'react'
import { IHotsiteRegisterForm, resetRegisterForm, updateRegisterForm } from '../../store'
import { handleFormValidity, handlePasswordAndConfirmPasswordEquality, ifNotEmptyValidatePassword } from '../../FormActions/validations'
import { store } from 'store'
import { HotsiteFormMain } from '../../..'
import { ifNotEmptyValidateCEP, ifNotEmptyValidateEmail, ifNotEmptyValidateMobile, isEmptyField } from '../../../validations'
import { Col, Form, Row } from 'react-bootstrap'
import { applyMaskIfNotEmpty, applyTitleOnError } from 'sharedKernel/handlers'
import { BaseButton, DescriptedLabel, Input, Password } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { DROPDOWN_ACTIVITY, DROPDOWN_BRAZIL_STATES, DROPDOWN_BUSINESS_MODEL, DROPDOWN_COUNTRIES, DROPDOWN_DEVELOPMENT_STAGE, DROPDOWN_NUMBER_EMPLOYEES, DROPDOWN_TECHNOLOGIES, INPUT_MASK_FORMAT, RESOLVER_INVESTMENTE_SOURCE } from 'sharedKernel/constants'
import { FormTitle, PasswordAcceptance, SectionTitle, StepButtonsWrapper, StepTransition } from './style'
import { passwordAcceptance } from 'components/Password/Rules'
import { register } from 'react-scroll/modules/mixins/scroller'
import { regexPatterns } from 'sharedKernel/regex'
import { getAddressByZIP } from 'sharedKernel/api/utils/cep'
import { showToast } from 'components/toast'
import { IViaCepServiceAddressResponse } from 'sharedKernel/api/utils/cep/contract'
import { IFormFieldProperties } from '../../../contracts'
import { LoginButton } from '../../FormActions/Login'
import { RegisterActions } from '../../FormActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { set } from 'lodash'
// #endregion
export interface ResolverRegisterFormProps {
  registerFormStateProps: IHotsiteRegisterForm
  registerUrl: string
  userType: string
}

export const ResolverRegisterForm = ({ registerFormStateProps, registerUrl, userType }): JSX.Element => {
  const registerFormState = { ...registerFormStateProps }
  const [step, setStep] = useState(0)
  const [inTransition, setInTransition] = useState(false)
  const [outTransition, setoutTransition] = useState(false)
  const [showInvestment, setShowInvestment] = useState(false)
  const [showOtherActivity, setShowOtherActivity] = useState(false)
  const [showOtherInvestmentSource, setShowOtherInvestmentSource] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const passwordAcceptanceDescription = passwordAcceptance()

  // #region Component labels
  const labels = {
    formTitleResolver: trans('Cadastre-se e faça parte da comunidade'),
    startupName: trans('Nome da Startup/Projeto'),
    startupNamePlaceholder: trans('Insira o nome da sua empresa'),
    contactName: trans('Nome do Responsável'),
    namePlaceholder: trans('Insira seu nome'),
    email: trans('E-mail'),
    emailPlaceholder: trans('Insira seu e-mail'),
    phone: trans('Telefone de contato do responsável'),
    phonePlaceholder: trans('Insira seu número de celular'),
    pitch: trans('Descrição para um pitch'),
    cep: trans('CEP'),
    cepSearch: trans('Não sabe seu CEP?'),
    cepPlaceholder: trans('Insira seu cep'),
    cnpj: trans('CNPJ'),
    lineOne: trans('Endereço'),
    lineOnePlaceholder: trans('Insira seu endereço'),
    addressLineOneNumber: trans('Número'),
    addressLineOneNumberPlaceholder: trans('Insira o número'),
    lineTwo: trans('Complemento'),
    lineTwoPlaceholder: trans('Insira o complemento'),
    city: trans('Cidade'),
    cityPlaceholder: trans('Insira sua cidade'),
    state: trans('Estado'),
    country: trans('País'),
    startupWebsite: trans('Site da startup/projeto'),
    password: trans('Senha'),
    passwordPlaceholder: trans('Insira sua senha'),
    passwordConfirmation: trans('Confirmação de senha'),
    passwordConfirmationPlaceholder: trans('Confirme sua senha'),
    titleMessage: trans('Preencha este campo'),
    passwordAcceptance: trans('Sua senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um número, um caracter especial'),
    next: trans('Próximo'),
    back: trans('Voltar'),
    register: trans('Cadastrar'),
    activity: trans('Qual é a Vertical/Setor de atuação?'),
    businessModel: trans('Qual é o modelo de negocio?'),
    numberEmployees: trans('Número de colaboradores'),
    technologies: trans('Quais as tecnologias?'),
    developmentStage: trans('Qual o estágio de desenvolvimento?'),
    annualTurnover: trans('Faturamento anual'),
    amount: trans('Se sim, quanto?'),
    hasRaisedInvestment: trans('Já captou investimento?'),
    investmentSource: trans('Se sim, qual foi a origem do investimento?'),
    otherInvestmentSource: trans('Qual outra origem do investimento?')
  }
  // #endregion

  // #region Component Methods
  useEffect(() => {
    return () => {
      store.dispatch(resetRegisterForm())
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const handleChange = (value, key): void => {
    registerFormState.form[key].value = value
    store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
  }

  const nextStep = (): void => {
    if (!isValidSection(resolverForm.sections[step])) {
      return
    }
    setInTransition(true)
    setTimeout(() => {
      setStep(step + 1)
      setInTransition(false)
    }, 500)
  }

  const passwordAndConfirmPasswordValidations = (currentPassword: IFormFieldProperties): boolean => {
    if (ifNotEmptyValidatePassword(currentPassword)) {
      return handlePasswordAndConfirmPasswordEquality(
        registerFormState?.form.password,
        registerFormState?.form.confirmPassword
      )
    }
    return false
  }

  const isValidSection = (section: Section): boolean => {
    let isValid = true
    section.rows.forEach((row) => {
      row.cols.forEach((col) => {
        col.fields.forEach((field) => {
          if (field.isInvalid === true || (field.isRequired && field.value === '')) {
            triggerValidation(field)
            isValid = false
          }
        })
      })
    })
    return isValid
  }
  const triggerValidation = (field: Field): void => {
    if (field.onBlur != null) {
      field.onBlur()
    }
  }

  const backStep = (): void => {
    setoutTransition(true)
    setTimeout(() => {
      setStep(step - 1)
      setoutTransition(false)
    }, 500)
  }

  const getInput = (field: Field): JSX.Element => {
    if (field.type === 'select') {
      return (
        <>
          <DescriptedLabel>{`${field.label}`}</DescriptedLabel>{field.isRequired && <span style={{ color: 'red' }}> *</span>}
          <Form.Select
            id={field.id}
            value={field.value}
            onChange={field.onChange}
            isInvalid={field.isInvalid}
            onBlur={field.onBlur}
            disabled={field.disabled}
          >
            {field.options?.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Form.Select>
        </>
      )
    }
    if (field.type === 'password') {
      return (
        <Password
          id={field.id}
          isRequired={field.isRequired}
          title={field.title}
          label={`${field.label}`}
          description={passwordAcceptanceDescription}
          placeholder={field.placeholder}
          value={field.value}
          isInvalid={field.isInvalid}
          notValidFieldMessage={field.notValidFieldMessage}
          onChange={field.onChange}
          onBlur={field.onBlur}
        ></Password>
      )
    }
    if (field.type === 'number') {
      return (
        <Input
        id={field.id}
        maxLength={field.maxLength}
        isRequired={field.isRequired}
        title={field.title}
        label={field.label}
        placeholder={field.placeholder}
        value={field.value}
        mask={field.mask}
        code={field.code}
        isInvalid={field.isInvalid}
        notValidFieldMessage={field.notValidFieldMessage}
        onChange={(event) => { field.onChange(event) }}
        onBlur={field.onBlur}
        disabled={field.disabled}
        type='number'
      />
      )
    }
    return (
      <Input
        id={field.id}
        maxLength={field.maxLength}
        isRequired={field.isRequired}
        title={field.title}
        label={field.label}
        placeholder={field.placeholder}
        value={field.value}
        mask={field.mask}
        code={field.code}
        isInvalid={field.isInvalid}
        notValidFieldMessage={field.notValidFieldMessage}
        onChange={(event) => { field.onChange(event) }}
        onBlur={field.onBlur}
        disabled={field.disabled}
      />
    )
  }
  const handleZIPActions = async (zipcode: IFormFieldProperties): Promise<void> => {
    if (ifNotEmptyValidateCEP(zipcode)) {
      await autoFillAddressByZIP(registerFormState.form.zipcode.value.replace(regexPatterns.clearMaskedNumbers, ''))
    }
  }

  const autoFillAddressByZIP = async (zipcode: string): Promise<void> => {
    await getAddressByZIP(zipcode)
      .then((response) => {
        fillAddress(response)
      }).catch((error) => {
        showToast('error', error)
      })
  }

  const fillAddress = (address: IViaCepServiceAddressResponse): void => {
    handleChange(address.uf, 'state')
    handleLineOneChange(address.logradouro, 'lineOne')
    handleChange(address.localidade, 'city')
  }
  const handleLineOneChange = (value, key): void => {
    if (value === '') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
    handleChange(value, key)
  }
  // #endregion

  // #region Form Fields
  const resolverForm: IResolverForm = {
    sections: [
      {
        title: 'Informações gerais',
        rows: [
          {
            cols: [
              {
                fields: [
                  {
                    id: 'startupName',
                    maxLength: 60,
                    isRequired: registerFormState?.form.startupName?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.startupName.message, labels.titleMessage),
                    label: labels.startupName,
                    placeholder: labels.startupNamePlaceholder,
                    value: registerFormState?.form.startupName?.value,
                    isInvalid: registerFormState?.form.startupName?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.startupName?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'startupName')
                    },
                    onBlur() {
                      if (registerFormState.form.startupName != null) {
                        isEmptyField(registerFormState.form.startupName)
                      }
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'email',
                    maxLength: 60,
                    isRequired: registerFormState?.form.email?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.email.message, labels.titleMessage),
                    label: labels.email,
                    placeholder: labels.emailPlaceholder,
                    value: registerFormState?.form.email?.value,
                    isInvalid: registerFormState?.form.email?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.email?.message,
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'email')
                    },
                    onBlur() {
                      ifNotEmptyValidateEmail(registerFormState?.form.email)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'cnpj',
                    isRequired: registerFormState?.form.cnpj?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.cnpj.message, labels.titleMessage),
                    label: labels.cnpj,
                    placeholder: labels.cnpj,
                    value: registerFormState?.form.cnpj?.value,
                    notValidFieldMessage: registerFormState?.form.cnpj?.message,
                    code: 'masked',
                    mask: applyMaskIfNotEmpty(registerFormState?.form.cnpj.value, INPUT_MASK_FORMAT.CNPJ),
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'cnpj')
                    },
                    onBlur() {
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'contactName',
                    maxLength: 60,
                    isRequired: registerFormState?.form.contactName?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.contactName.message, labels.titleMessage),
                    label: labels.contactName,
                    placeholder: labels.namePlaceholder,
                    value: registerFormState?.form.contactName?.value,
                    isInvalid: registerFormState?.form.contactName?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.contactName?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'contactName')
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.contactName)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'phone',
                    isRequired: registerFormState?.form.phone?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.phone.message, labels.titleMessage),
                    label: labels.phone,
                    placeholder: labels.phonePlaceholder,
                    value: registerFormState?.form.phone?.value,
                    code: 'masked',
                    mask: applyMaskIfNotEmpty(registerFormState?.form.phone.value, INPUT_MASK_FORMAT.MOBILE),
                    isInvalid: registerFormState?.form.phone?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.phone?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'phone')
                    },
                    onBlur() {
                      ifNotEmptyValidateMobile(registerFormState?.form.phone)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'startupWebsite',
                    maxLength: 60,
                    isRequired: registerFormState?.form.startupWebsite?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.startupWebsite.message, labels.titleMessage),
                    label: labels.startupWebsite,
                    value: registerFormState?.form.startupWebsite?.value,
                    isInvalid: registerFormState?.form.startupWebsite?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.startupWebsite?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'startupWebsite')
                    },
                    onBlur() {
                      if (registerFormState.form.startupWebsite != null) {
                        isEmptyField(registerFormState.form.startupWebsite)
                      }
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'zipcode',
                    isRequired: registerFormState?.form.zipcode?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.zipcode.message, labels.titleMessage),
                    label: labels.cep,
                    value: registerFormState?.form.zipcode?.value,
                    code: 'masked',
                    mask: applyMaskIfNotEmpty(registerFormState?.form.zipcode.value, INPUT_MASK_FORMAT.CEP),
                    placeholder: labels.cepPlaceholder,
                    isInvalid: registerFormState?.form.zipcode?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.zipcode?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'zipcode')
                      handleZIPActions(registerFormState?.form.zipcode)
                    },
                    onBlur() {
                      if (registerFormState.form.zipcode != null) {
                        isEmptyField(registerFormState.form.zipcode)
                      }
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              },
              {
                fields: [
                  {
                    id: 'lineOne',
                    maxLength: 100,
                    isRequired: registerFormState?.form.lineOne?.isRequired,
                    label: labels.lineOne,
                    placeholder: labels.lineOnePlaceholder,
                    value: registerFormState?.form.lineOne?.value,
                    isInvalid: registerFormState?.form.lineOne?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.lineOne?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'lineOne')
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.lineOne)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'number',
                    isRequired: registerFormState?.form.number?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.number.message, labels.titleMessage),
                    label: labels.addressLineOneNumber,
                    placeholder: labels.addressLineOneNumberPlaceholder,
                    value: registerFormState?.form.number?.value,
                    isInvalid: registerFormState?.form.number?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.number?.message,
                    maxLength: 8,
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'number')
                    },
                    onBlur() {
                      isEmptyField(registerFormState?.form.number)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              },
              {
                fields: [
                  {
                    id: 'lineTwo',
                    maxLength: 100,
                    isRequired: registerFormState?.form.lineTwo?.isRequired,
                    label: labels.lineTwo,
                    placeholder: labels.lineTwoPlaceholder,
                    value: registerFormState?.form.lineTwo?.value,
                    isInvalid: registerFormState?.form.lineTwo?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.lineTwo?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'lineTwo')
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'city',
                    maxLength: 60,
                    isRequired: registerFormState?.form.city?.isRequired,
                    label: labels.city,
                    placeholder: labels.cityPlaceholder,
                    value: registerFormState?.form.city?.value,
                    isInvalid: registerFormState?.form.city?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.city?.message,
                    onChange: (event) => {
                      handleChange(event.target.value, 'city')
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.city)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'state',
                    isRequired: registerFormState?.form.state?.isRequired,
                    label: labels.state,
                    value: registerFormState?.form.state?.value,
                    isInvalid: registerFormState?.form.state?.isInvalid,
                    onChange: (event) => {
                      handleChange(event.target.value, 'state')
                      isEmptyField(registerFormState.form.state)
                    },
                    onBlur: () => {
                      isEmptyField(registerFormState.form.state)
                    },
                    options: DROPDOWN_BRAZIL_STATES,
                    type: 'select'
                  }
                ]
              },
              {
                fields: [
                  {
                    id: 'country',
                    isRequired: registerFormState?.form.country?.isRequired,
                    label: labels.country,
                    value: registerFormState?.form.country?.value,
                    isInvalid: registerFormState?.form.country?.isInvalid,
                    onChange: (event) => {
                      handleChange(event.target.value, 'country')
                      isEmptyField(registerFormState.form.country)
                    },
                    onBlur: () => {
                      isEmptyField(registerFormState.form.country)
                    },
                    options: DROPDOWN_COUNTRIES,
                    type: 'select'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        title: 'Sobre o negócio',
        rows: [
          {
            cols: [
              {
                fields: [
                  {
                    id: 'activity',
                    isRequired: registerFormState?.form.activity?.isRequired,
                    label: labels.activity,
                    value: registerFormState?.form.activity?.value,
                    isInvalid: registerFormState?.form.activity?.isInvalid,
                    onChange: (event) => {
                      if (event.target.value === 'Outro') {
                        setShowOtherActivity(true)
                      } else {
                        setShowOtherActivity(false)
                        handleChange('', 'otherActivity')
                      }
                      handleChange(event.target.value, 'activity')
                      isEmptyField(registerFormState.form.activity)
                    },
                    onBlur: () => {
                      isEmptyField(registerFormState.form.activity)
                    },
                    options: DROPDOWN_ACTIVITY,
                    type: 'select'
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'otherActivity',
                    maxLength: 40,
                    isRequired: registerFormState?.form.otherActivity?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.otherActivity.message, labels.titleMessage),
                    label: 'Outro',
                    value: registerFormState?.form.otherActivity?.value,
                    isInvalid: registerFormState?.form.otherActivity?.isInvalid,
                    disabled: !showOtherActivity,
                    onChange: (event) => {
                      handleChange(event.target.value, 'otherActivity')
                    },
                    onBlur() {
                      if (registerFormState.form.otherActivity != null) {
                        isEmptyField(registerFormState.form.otherActivity)
                      }
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'businessModel',
                    isRequired: registerFormState?.form.businessModel?.isRequired,
                    label: labels.businessModel,
                    value: registerFormState?.form.businessModel?.value,
                    isInvalid: registerFormState?.form.businessModel?.isInvalid,
                    onChange: (event) => {
                      handleChange(event.target.value, 'businessModel')
                      isEmptyField(registerFormState.form.businessModel)
                    },
                    onBlur: () => {
                      isEmptyField(registerFormState.form.businessModel)
                    },
                    options: DROPDOWN_BUSINESS_MODEL,
                    type: 'select'
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'numberEmployees',
                    isRequired: registerFormState?.form.numberEmployees?.isRequired,
                    label: labels.numberEmployees,
                    value: registerFormState?.form.numberEmployees?.value,
                    isInvalid: registerFormState?.form.numberEmployees?.isInvalid,
                    onChange: (event) => {
                      handleChange(event.target.value, 'numberEmployees')
                      isEmptyField(registerFormState.form.numberEmployees)
                    },
                    onBlur: () => {
                      isEmptyField(registerFormState.form.numberEmployees)
                    },
                    options: DROPDOWN_NUMBER_EMPLOYEES,
                    type: 'select'
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'technologies',
                    maxLength: 100,
                    isRequired: registerFormState?.form.technologies?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.technologies.message, labels.titleMessage),
                    label: labels.technologies,
                    value: registerFormState?.form.technologies?.value,
                    isInvalid: registerFormState?.form.technologies?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.technologies?.message,
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'technologies')
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.technologies)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'pitch',
                    maxLength: 100,
                    isRequired: registerFormState?.form.pitch?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.pitch.message, labels.titleMessage),
                    label: labels.pitch,
                    value: registerFormState?.form.pitch?.value,
                    isInvalid: registerFormState?.form.pitch?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.pitch?.message,
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'pitch')
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.pitch)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        title: 'Sobre o desenvolvimento',
        rows: [
          {
            cols: [
              {
                fields: [
                  {
                    id: 'developmentStage',
                    isRequired: registerFormState?.form.developmentStage?.isRequired,
                    label: labels.developmentStage,
                    value: registerFormState?.form.developmentStage?.value,
                    type: 'select',
                    isInvalid: registerFormState?.form.developmentStage?.isInvalid,
                    options: DROPDOWN_DEVELOPMENT_STAGE,
                    onChange: (event) => {
                      handleChange(event.target.value, 'developmentStage')
                      isEmptyField(registerFormState.form.developmentStage)
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.developmentStage)
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'annualTurnover',
                    isRequired: registerFormState?.form.annualTurnover?.isRequired,
                    label: labels.annualTurnover,
                    value: registerFormState?.form.annualTurnover?.value,
                    type: 'number',
                    onChange: (event) => {
                      handleChange(event.target.value, 'annualTurnover')
                    },
                    onBlur() {
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'hasRaisedInvestment',
                    isRequired: registerFormState?.form.hasRaisedInvestment?.isRequired,
                    label: labels.hasRaisedInvestment,
                    value: registerFormState?.form.hasRaisedInvestment?.value,
                    isInvalid: registerFormState?.form.hasRaisedInvestment?.isInvalid,
                    type: 'select',
                    options: [
                      { label: 'Selecione', value: '' },
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' }
                    ],
                    onChange: (event) => {
                      if (event.target.value === 'true') {
                        setShowInvestment(true)
                      } else {
                        handleChange('', 'amount')
                        // handleChange('', 'investmentSource')
                        setShowInvestment(false)
                      }
                      handleChange(event.target.value.trim(), 'hasRaisedInvestment')
                      isEmptyField(registerFormState.form.hasRaisedInvestment)
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.hasRaisedInvestment)
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'amount',
                    type: 'number',
                    disabled: !showInvestment,
                    isRequired: registerFormState?.form.amount?.isRequired,
                    label: labels.amount,
                    value: registerFormState?.form.amount?.value,
                    onChange: (event) => {
                      handleChange(event.target.value, 'amount')
                    },
                    onBlur() {
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'investmentSource',
                    maxLength: 30,
                    disabled: !showInvestment,
                    isRequired: registerFormState?.form.investmentSource?.isRequired,
                    label: labels.investmentSource,
                    value: registerFormState?.form.investmentSource?.value,
                    type: 'select',
                    options: RESOLVER_INVESTMENTE_SOURCE,
                    onChange: (event) => {
                      if (event.target.value === 'Outro') {
                        setShowOtherInvestmentSource(true)
                      } else {
                        handleChange('', 'otherInvestmentSource')
                        setShowOtherInvestmentSource(false)
                      }
                      handleChange(event.target.value, 'investmentSource')
                      isEmptyField(registerFormState.form.investmentSource)
                    },
                    onBlur() {
                      isEmptyField(registerFormState.form.investmentSource)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          },
          {
            cols: [
              {
                fields: [
                  {
                    id: 'otherInvestmentSource',
                    maxLength: 30,
                    disabled: !showOtherInvestmentSource,
                    isRequired: registerFormState?.form.otherInvestmentSource?.isRequired,
                    label: labels.otherInvestmentSource,
                    value: registerFormState?.form.otherInvestmentSource?.value,
                    onChange: (event) => {
                      handleChange(event.target.value, 'otherInvestmentSource')
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        title: 'Segurança',
        rows: [
          {
            cols: [
              {
                fields: [
                  {
                    id: 'password',
                    isRequired: registerFormState?.form.password?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.password.message, labels.titleMessage),
                    label: labels.password,
                    placeholder: labels.passwordPlaceholder,
                    value: registerFormState?.form.password?.value,
                    type: 'password',
                    isInvalid: registerFormState?.form.password?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.password?.message,
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'password')
                    },
                    onBlur() {
                      passwordAndConfirmPasswordValidations(registerFormState?.form.password)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              },
              {
                fields: [
                  {
                    id: 'confirmPassword',
                    isRequired: registerFormState?.form.confirmPassword?.isRequired,
                    title: applyTitleOnError(registerFormState?.form.confirmPassword.message, labels.titleMessage),
                    label: labels.passwordConfirmation,
                    placeholder: labels.passwordConfirmationPlaceholder,
                    value: registerFormState?.form.confirmPassword?.value,
                    type: 'password',
                    isInvalid: registerFormState?.form.confirmPassword?.isInvalid,
                    notValidFieldMessage: registerFormState?.form.confirmPassword?.message,
                    onChange: (event) => {
                      handleChange(event.target.value.trim(), 'confirmPassword')
                    },
                    onBlur() {
                      passwordAndConfirmPasswordValidations(registerFormState?.form.confirmPassword)
                      store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
  // #endregion
  return (
      <HotsiteFormMain formTitle={labels.formTitleResolver} >
        {step === 0 && (
          <>
            <FormTitle>{labels.formTitleResolver}</FormTitle>
            <hr />
          </>
        )}
        <StepTransition className={`${inTransition ? 'enter' : ''} ${outTransition ? 'exit' : ''}`}>
          <SectionTitle hasIcon={step > 0}>
            {step > 0 && (
              <a onClick={backStep} title={labels.back} className='cursor-pointer'>
                <FontAwesomeIcon className="" size='1x' icon={['fas', 'arrow-alt-circle-left']} />
              </a>
            )}
            <span>
              {resolverForm.sections.length === step ? 'teste' : resolverForm.sections[step].title}
            </span>
          </SectionTitle>
          {resolverForm.sections[step].rows.map((row, rowIndex) => (
              <Row key={rowIndex} className='my-2'>
                {row.cols.map((col, colIndex) => (
                  <Col lg={true} key={colIndex}>
                    {col.fields.map((field, fieldIndex) => (
                      <div key={fieldIndex} className='mb-2'>
                        {getInput(field)}
                      </div>
                    ))}
                  </Col>
                ))}
              </Row>
          ))}
        </StepTransition>
        <StepButtonsWrapper className='px-2 mt-2'>
          {(resolverForm.sections.length - 1) === step
            ? (
              <>
                <Row>
                    <PasswordAcceptance isInvalid={registerFormState?.form.password.isInvalid}>{labels.passwordAcceptance}</PasswordAcceptance>
                </Row>
                <RegisterActions registerUrl={registerUrl} userType={userType} registerFormState={registerFormState} registerType='Resolver' />
              </>
              )
            : (
                <BaseButton onClick={nextStep} size="big" btnContent={labels.next} variant="primary-invert" />
              )}
        </StepButtonsWrapper>
        <hr className='mt-4' />
        <LoginButton />
      </HotsiteFormMain>
  )
}
