export const GOOGLE_FORM_CONTACT_ACTION = 'https://docs.google.com/forms/d/e/1FAIpQLSfqgIp7aJxUQZfY2GhlomgPKQcJWNvxme_e4R8bb6Bw_psLeQ/formResponse?&submit=Submit??usp=pp_url&'

export const GOOGLE_FORM_NAME_ID = 'entry.1843729948'
export const GOOGLE_FORM_NICHE_ID = 'entry.1937683157'
export const GOOGLE_FORM_COMPANY_NAME_ID = 'entry.2056664448'
export const GOOGLE_FORM_ROLE_ID = 'entry.1723800242'
export const GOOGLE_FORM_ANOTHER_ROLE_ID = 'entry.2061071758'
export const GOOGLE_FORM_EMAIL_ID = 'entry.1410895613'
export const GOOGLE_FORM_PHONE_ID = 'entry.1049121038'
export const GOOGLE_FORM_SUBJECT_ID = 'entry.354730546'
export const GOOGLE_FORM_CHECKBOX_GROUP_ID = 'entry.57805467'
export const GOOGLE_FORM_ACCEPT_TERMS_ID = 'entry.824668315'
