import {
  ApplicationFormSectionFields,
  DynamicFormField,
  EvaluationSectionStep,
  EvaluationSectionStepSection,
  IHubChallengeBasicFormState,
  IInputDynamicOption
} from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/store'
import {
  IApplicationForm,
  IEvaluation,
  IMultiFile,
  ISection,
  ITab
} from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/constants'
import _ from 'lodash'
import { IHubChallengeBasicFormFilesState } from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/files/store'

export function extractData(
  state: IHubChallengeBasicFormState,
  formFilesState: IHubChallengeBasicFormFilesState,
  form: ITab[]
): void {
  _.set(state, 'config.title', _.get(form, '[0].content[0].value'))
  _.set(state, 'config.niches', _.get(form, '[0].content[1].value'))
  _.set(state, 'style.shortDescription', _.get(form, '[0].content[2].value'))
  _.set(state, 'style.longDescription', _.get(form, '[0].content[3].value'))
  _.set(state, 'config.step.launchDate', _.get(form, '[0].content[4].value'))
  _.set(state, 'config.step.applicationStartDate', _.get(form, '[0].content[4].value'))
  _.set(state, 'config.step.finalSelectionDate', _.get(form, '[0].content[6].value'))
  _.set(state, 'config.step.evaluationStartDate', _.get(form, '[0].content[7].value'))
  _.set(state, 'config.step.closingDate', _.get(form, '[0].content[8].value'))
  _.set(state, 'config.limitOfIdeas', _.get(form, '[0].content[9].value'))
  _.set(state, 'style.youtubeLink', _.get(form, '[0].content[10].value'))
  _.set(state, 'style.youtubeLinkTitle', _.get(form, '[0].content[11].value'))
  _.set(state, 'style.youtubeLink2', _.get(form, '[0].content[12].value'))
  _.set(state, 'style.youtubeLink2Title', _.get(form, '[0].content[13].value'))
  _.set(state, 'style.youtubeLink3', _.get(form, '[0].content[14].value'))
  _.set(state, 'style.youtubeLink3Title', _.get(form, '[0].content[15].value'))

  extractFiles(formFilesState, form, '[0].content[16].archive', 'coverImage')
  extractFiles(formFilesState, form, '[0].content[17].archive', 'homePageSlider')
  extractFiles(formFilesState, form, '[0].content[18].archive', 'presentationVideo')
  const multiFiles = form[0].content[19] as IMultiFile
  multiFiles.archives?.forEach((file, key) => {
    extractFiles(formFilesState, form, `[0].content[19].archives[${String(key)}]`, 'supportFiles')
  })
  extractRemovedFiles(formFilesState, form, '[0].content[16]')
  extractRemovedFiles(formFilesState, form, '[0].content[17]')
  extractRemovedFiles(formFilesState, form, '[0].content[18]')
  extractRemovedFiles(formFilesState, form, '[0].content[19]')
  _.set(state, 'style.isActive', _.get(form, '[0].content[20].value'))

  const applicationForm = form[1].content[0] as IApplicationForm
  state.applicationForm.id = applicationForm.id.includes('_idScreen') ? undefined : applicationForm.id
  const applicationFormSections: ApplicationFormSectionFields[] = []

  applicationForm.sections?.forEach((section, sectionKey) => {
    if (section.id.includes('_idScreen') && section.isDeleted) {
      return
    }
    const applicationFormSection: ApplicationFormSectionFields = extractSection(section, sectionKey)
    applicationFormSections.push(applicationFormSection)
  })
  state.applicationForm.sections = applicationFormSections

  const evaluation = form[2].content[0] as IEvaluation
  const formEvaluationSteps: EvaluationSectionStep[] = []

  state.evaluation.id = evaluation.id.includes('_idScreen') ? undefined : evaluation.id
  evaluation.steps.forEach((step, stepKey) => {
    const formEvaluationStep: EvaluationSectionStep = {
      id: step.id.includes('_idScreen') ? undefined : step.id,
      isDeleted: step.isDeleted,
      name: step.name,
      order: stepKey,
      sections: []
    }
    step.sections.forEach((section, sectionKey) => {
      if (section.id.includes('_idScreen') && section.isDeleted) {
        return
      }
      const evaluationSection: EvaluationSectionStepSection = extractSection(
        section,
        sectionKey
      ) as EvaluationSectionStepSection
      formEvaluationStep.sections.push(evaluationSection)
    })
    formEvaluationSteps.push(formEvaluationStep)
  })
  state.evaluation.steps = formEvaluationSteps
}

function extractSection(section: ISection, order: number): ApplicationFormSectionFields {
  const applicationFormSectionFields: ApplicationFormSectionFields = {
    fields: [],
    id: section.id.includes('_idScreen') ? undefined : section.id,
    isDeleted: section.isDeleted,
    isHidden: section.isHidden,
    name: section.name,
    order: order
  }
  section.fields?.forEach((field, fieldKey) => {
    if (field.id.includes('_idScreen') && field.isDeleted) {
      return
    }
    applicationFormSectionFields.fields.push(extractFields(field, fieldKey))
  })

  return applicationFormSectionFields
}

function extractFields(field, order): DynamicFormField {
  const dynamicFormField: DynamicFormField = {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    id: field.id.includes('_idScreen') ? undefined : field.id,
    description: field.description,
    size: field.size,
    isRemovable: false,
    isRequired: Boolean(field.isCriterion) || field.isRequired,
    isHidden: Boolean(field.isHidden) || field.isHidden,
    isDeleted: field.isDeleted,
    name: field.name,
    options: [],
    type: field.questionType,
    weight: field.weight,
    order: order,
    gradeInfo: field.gradeInfo
  }

  field.options?.forEach((option, optionKey) => {
    if (Boolean(option.id.includes('_idScreen')) && Boolean(option.isDeleted)) {
      return
    }
    const inputDynamicOption: IInputDynamicOption = extractOptions(option, optionKey)
    dynamicFormField.options.push(inputDynamicOption)
  })
  return dynamicFormField
}

function extractOptions(option, order): IInputDynamicOption {
  const inputDynamicOption: IInputDynamicOption = {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    id: option.id.includes('_idScreen') ? undefined : option.id,
    isDeleted: option.isDeleted,
    value: option.value,
    order: order
  }
  return inputDynamicOption
}

function extractFiles(
  formFilesState: IHubChallengeBasicFormFilesState,
  form: ITab[],
  path: string,
  type: string
): void {
  const file = _.get(form, path)
  if (file === undefined) {
    return
  }

  // eslint-disable-next-line no-extra-boolean-cast
  if (!Boolean(file.isDeleted) && file.id !== undefined && Boolean(file.id.includes('_idScreen'))) {
    formFilesState.challengeFiles.push({
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      id: undefined,
      name: file.name,
      size: file.size,
      mimetype: file.mimetype,
      type: type,
      sourceFile: file.sourceFile
    })
  }
}

function extractRemovedFiles(formFilesState: IHubChallengeBasicFormFilesState, form: ITab[], path: string): void {
  const file = _.get(form, path)
  if (file === undefined) {
    return
  }

  if (file.removed === undefined || file.removed.length <= 0) {
    return
  }

  file.removed.forEach((removed) => {
    formFilesState.deletedChallengeFiles.push({
      id: removed,
      mimetype: '',
      name: '',
      size: 0,
      type: ''
    })
  })
}
