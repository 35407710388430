/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Tippy from '@tippyjs/react'
import { Loading } from 'components/loading'
import { trans } from 'sharedKernel/i18n'
import { ChallengeVisualizationProps } from './contracts'
import { LoadindContainer, ScreenContainer } from './styles'
import { ROUTES } from 'sharedKernel/constants'
import {
  AttachmentsArea,
  AttachmentsItem,
  AttachmentsTitle,
  CoverImage, DescriptionText,
  DescriptionTitle,
  IdeasLimit,
  LinksArea,
  PresentationArea,
  PresentationVideo,
  Title
} from 'sharedKernel/styledComponents/visualizationComponents/styles'
import { Timeline } from 'components/Timeline'
import { isCurrentDateBiggerThanDeadline, isStartDateGreaterThanCurrentDate } from 'sharedKernel/handlers'

export const ChallengeVisualization = ({
  isFetching,
  data,
  isFileFetching,
  fileData,
  isButtonActive = true
}: ChallengeVisualizationProps): JSX.Element => {
  const navigate = useNavigate()
  const filesChallenge = {
    challengeFiles: fileData?.filter((file) => file.type === 'supportFiles'),
    homePageSlider: fileData?.find((file) => file.type === 'homePageSlider'),
    presentationVideo: fileData?.find((file) => file.type === 'presentationVideo'),
    coverImage: fileData?.find((file) => file.type === 'coverImage')
  }

  const labels = {
    description: trans('Descrição'),
    attachments: trans('Anexos'),
    supportFilesNotFound: trans('Nenhum arquivo de apoio foi incluído neste evento'),
    buttonText: trans('Inscreva-se'),
    closed: trans('Encerrado'),
    signUpSoon: trans('Inscreva-se em breve'),
    tooltipYoutubeIcon: trans('Vídeo de apresentação'),
    tooltipYoutube2: trans('Segundo Vídeo'),
    tooltipYoutube3: trans('Terceiro Vídeo')
  }

  if (isFetching ?? false) {
    return (
      <LoadindContainer>
        <Loading />
      </LoadindContainer>
    )
  }
  const isClosed = isCurrentDateBiggerThanDeadline(data.data.config.step.finalSelectionDate)
  const startDateNotReached = isStartDateGreaterThanCurrentDate(data.data.config.step.applicationStartDate)
  const getChallengeImage = (url?: string): string => {
    const fallBackImage = require('../../../../assets/images/Imagem_do_programa.png')

    return url != null && url.length > 0 ? url : fallBackImage
  }

  const getPageButton = (): JSX.Element => {
    if (!isButtonActive) {
      return <></>
    }
    if (isClosed) {
      return (
        <IdeasLimit>{labels.closed}</IdeasLimit>
      )
    }

    if (startDateNotReached) {
      return (
        <IdeasLimit>{labels.signUpSoon}</IdeasLimit>
      )
    }

    return (
      <BaseButton
        btnContent={labels.buttonText}
        variant="primary"
        size="big"
        isFullWidth
        onClick={() => navigate(ROUTES.HOTSITE.REGISTER.RESOLVER)}
      />
    )
  }

  return (
    <ScreenContainer>
      <CoverImage backgroundImg={getChallengeImage(filesChallenge?.homePageSlider?.path)} />
      <Row>
        <Col sm={4} xxl={2}>
          <Row>
            <Col sm={12}>
              {getPageButton()}
            </Col>
            <Col sm={12} className="gy-3">
              {data.data.style.youtubeLink !== ''
                ? (
                  <Tippy
                  content={data.data.style.youtubeLinkTitle !== '' && data.data.style.youtubeLinkTitle !== undefined && data.data.style.youtubeLinkTitle !== null ? data.data.style.youtubeLinkTitle : labels.tooltipYoutubeIcon}
                  animation='scale'
                  arrow={false}
                  maxWidth="100%"
                  theme='primary'
                  allowHTML={true}
                >
                  <a href={data.data.style.youtubeLink} target="_blank" className="me-2">
                    <BaseButton
                      btnContent={<FontAwesomeIcon icon={['fab', 'youtube']} />}
                      variant="primary-invert"
                      size="icon"
                    />
                  </a>
                  </Tippy>
                  )
                : (
                  <></>
                  )}
              {data.data.style.youtubeLink2 !== '' && data.data.style.youtubeLink2 !== undefined && data.data.style.youtubeLink2 !== null
                ? (
                  <Tippy
                  content={data.data.style.youtubeLink2Title !== '' && data.data.style.youtubeLink2Title !== undefined && data.data.style.youtubeLink2Title !== null ? data.data.style.youtubeLink2Title : labels.tooltipYoutube2}
                  animation='scale'
                  arrow={false}
                  maxWidth="100%"
                  theme='primary'
                  allowHTML={true}
                >
                  <a href={data.data.style.youtubeLink2} target="_blank" className="me-2">
                    <BaseButton
                      btnContent={<FontAwesomeIcon icon={['fab', 'youtube']} />}
                      variant="primary-invert"
                      size="icon"
                    />
                  </a>
                  </Tippy>
                  )
                : (
                  <></>
                  )}
                {data.data.style.youtubeLink3 !== '' && data.data.style.youtubeLink3 !== undefined && data.data.style.youtubeLink3 !== null
                  ? (
                    <Tippy
                  content={data.data.style.youtubeLink3Title !== '' && data.data.style.youtubeLink3Title !== undefined && data.data.style.youtubeLink3Title !== null ? data.data.style.youtubeLink3Title : labels.tooltipYoutube3}
                  animation='scale'
                  arrow={false}
                  maxWidth="100%"
                  theme='primary'
                  allowHTML={true}
                >
                  <a href={data.data.style.youtubeLink3} target="_blank" className="me-2">
                    <BaseButton
                      btnContent={<FontAwesomeIcon icon={['fab', 'youtube']} />}
                      variant="primary-invert"
                      size="icon"
                    />
                  </a>
                  </Tippy>
                    )
                  : (
                  <></>
                    )}
            </Col>
            <Col sm={12} className="gy-4">
              <AttachmentsArea>
                <AttachmentsTitle>{labels.attachments}:</AttachmentsTitle>
                {!isFileFetching && filesChallenge.challengeFiles !== undefined
                  ? (
                      filesChallenge.challengeFiles.map((file, index) => {
                        return (
                        <AttachmentsItem key={`attachments-item-${index}`}>
                          <a key={`item-${index}`} href={file.path} target="_blank" download={file.name}>
                            {file.name}
                          </a>
                        </AttachmentsItem>
                        )
                      })
                    )
                  : (
                    <AttachmentsItem>{labels.supportFilesNotFound}</AttachmentsItem>
                    )}
              </AttachmentsArea>
              <LinksArea>
                {data.data.style.youtubeLink !== '' ? (<a href={data.data.style.youtubeLink} target="_blank">{data.data.style.youtubeLinkTitle !== '' && data.data.style.youtubeLinkTitle !== undefined && data.data.style.youtubeLinkTitle !== null ? data.data.style.youtubeLinkTitle : data.data.style.youtubeLink}</a>) : (<></>)}
              </LinksArea>
              <LinksArea>
                {(data.data.style.youtubeLink2 !== '' && data.data.style.youtubeLink2 !== undefined && data.data.style.youtubeLink2 !== null) ? (<a href={data.data.style.youtubeLink2} target="_blank">{data.data.style.youtubeLink2Title !== '' && data.data.style.youtubeLink2Title !== undefined && data.data.style.youtubeLink2Title !== null ? data.data.style.youtubeLink2Title : data.data.style.youtubeLink2}</a>) : (<></>)}
              </LinksArea>
              <LinksArea>
                {(data.data.style.youtubeLink3 !== '' && data.data.style.youtubeLink3 !== undefined && data.data.style.youtubeLink3 !== null) ? (<a href={data.data.style.youtubeLink3} target="_blank">{data.data.style.youtubeLink3Title !== '' && data.data.style.youtubeLink3Title !== undefined && data.data.style.youtubeLink3Title !== null ? data.data.style.youtubeLink3Title : data.data.style.youtubeLink3}</a>) : (<></>)}
              </LinksArea>
            </Col>
          </Row>
        </Col>
        <Col sm={8} xxl={10}>
          <Row>
            <Col sm={12}>
              <Title>{data.data.config.title}</Title>
            </Col>
            <Col sm={12} className="gy-4">
              <Timeline steps={data.data.config.step} />
            </Col>
            <Col sm={12} className="gy-4">
              <DescriptionTitle>{labels.description}</DescriptionTitle>
              <hr />
              <DescriptionText>
                {data.data.style.shortDescription}
                <br />
                <div style={{ marginTop: '2rem' }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.data.style.longDescription }}
                  />
                </div>
              </DescriptionText>
              <PresentationArea className="d-flex justify-content-center">
                {!isFileFetching && filesChallenge.presentationVideo !== undefined
                  ? (
                    <PresentationVideo src={filesChallenge.presentationVideo.path} controls />
                    )
                  : (
                    <p></p>
                    )}
              </PresentationArea>
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenContainer>
  )
}
