import { DynamicFormField } from '../../contracts'
import { changeApplicationForm, store, TReducers, useSelector } from '../../../../../../../../store'
import { Option } from 'iebt-zing-storybook/dist/components/Fields/Inputs/Input'
import { Input } from 'iebt-zing-storybook'
import { getValue, setValue, getDescription } from '../../actions/dataHandler'
import React, { useEffect, useState } from 'react'
import Tippy from '@tippyjs/react'

export const ApplicationFormInputUrl = (question: DynamicFormField): JSX.Element => {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const isLaunched = applicationFormState.isLaunched ?? false

  const [urlError, setUrlError] = useState<string>('')

  const handleChange = (event): void => {
    const element = event?.target as HTMLInputElement
    const newValue = element.value
    const newApplicationFormState = setValue(applicationFormState, question.id, newValue, question.isRequired)
    store.dispatch(changeApplicationForm(newApplicationFormState))
  }

  const handleBlur = (event): void => {
    const element = event?.target as HTMLInputElement
    const newValue = element.value
    if (newValue !== '' && !checkUrl(newValue)) {
      setUrlError('Formato de link inválido')
    } else {
      setUrlError('')
    }
  }

  const checkUrl = (answer: string): boolean => {
    try {
      const url = new URL(answer)
      return true
    } catch (err) {
      return false
    }
  }

  return (
    <div className="mb-3" key={question.id}>
      <Input
        code={'text'}
        type={question.type}
        name={question.name}
        disabled={isLaunched}
        label={question.name}
        placeholder={question.description}
        isRequired={question.isRequired}
        isInvalid={urlError !== '' || question.isInvalid}
        notValidFieldMessage={urlError.length > 0 ? urlError : question.notValidFieldMessage}
        value={getValue(applicationFormState, question.id) as string}
        onChange={handleChange}
        onBlur={handleBlur}
        />
    </div>
  )
}
