import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { HotsiteChallengeProps } from 'domain/feature/hotsite/store/contracts'
import { saveHotsiteChallenges, store } from 'store'
import { HotsiteMain } from 'domain/feature/hotsite/Main'

export const MainPage = (): JSX.Element => {
  const request: GetFormat = {
    url: '/v1/hotsite/challenge'
  }
  const { data } = useGet<HotsiteChallengeProps[]>(request, 'hotsite-challenges')

  if (data !== undefined) {
    data.rows = data?.rows.filter((challenge) => challenge.isActive)
    store.dispatch(saveHotsiteChallenges(data))
  }

  return (
    <>
      <HotsiteMain challenges={data?.rows ?? []} />
    </>
  )
}
