/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { EventHandler, useState } from 'react'
import { ADJUST_TOP, IApplicationForm, IDynamicField, IDynamicFieldOption, IField, ISection, IStep } from '../constants'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { duplicateField, duplicateSection } from '../behaviors/duplicateItems'
import { BuildField } from './field'
import { AddButton, LightButton } from '../../FormWrapper/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trans } from 'sharedKernel/i18n'

interface SectionAreaProps {
  initialState: IApplicationForm | IStep
  stateKey: number
  sectionIdentifier: string
  updateState: (item) => void
  availableQuestionTypes?: IDynamicFieldOption[]
  defaultQuestionType?: string
  disabled?: boolean
}
export const SectionArea = ({
  initialState,
  stateKey,
  sectionIdentifier = 'section',
  updateState,
  availableQuestionTypes = [
    {
      label: 'Texto',
      value: 'text'
    },
    {
      label: 'Lista suspensa',
      value: 'select'
    },
    {
      label: 'Múltipla escolha',
      value: 'radio-group'
    },
    {
      label: 'Caixas de Seleção',
      value: 'checkbox-group'
    },
    {
      label: 'Data',
      value: 'date'
    },
    {
      label: 'Número',
      value: 'number'
    },
    {
      label: 'Upload de arquivo',
      value: 'upload'
    },
    {
      label: 'Link',
      value: 'url'
    }
  ],
  defaultQuestionType = 'text',
  disabled
}: SectionAreaProps): any => {
  const isApplicationForm = sectionIdentifier === 'applicationForm'
  const isEvaluationForm = sectionIdentifier === 'evaluation.steps[0]'
  const currentForm = isApplicationForm ? 'applicationForm' : 'evaluation'
  const inApplicationForm = isApplicationForm ? 0 : 1
  const onDragEnded = (result: DropResult): void => {
    if (result.destination == null) {
      return
    }

    const sourcePath = `${result.source.droppableId}[${result.source.index}]`
    const destinationPath = `${result.destination.droppableId}[${result.destination.index}]`

    if (sourcePath === destinationPath) {
      return
    }

    const source = _.get(item, sourcePath)
    const destination = _.get(item, destinationPath)

    // AMONG DESTINATIONS
    if (result.destination.droppableId !== result.source.droppableId) {
      _.set(item, `${result.destination.droppableId}[${_.get(item, result.destination.droppableId).length}]`, source)
      _.get(item, result.source.droppableId).splice(result.source.index, 1)
      setItem({ ...item })
      return
    }

    _.set(item, sourcePath, destination)
    _.set(item, destinationPath, source)
    setItem({ ...item })
    updateState(item)
  }

  const [item, setItem] = useState<IApplicationForm | IStep>(initialState)

  const updateFieldState = (sectionKey: number, fieldKey: number, field: IField): void => {
    // @ts-expect-error
    item.sections[sectionKey].fields[fieldKey] = field
    setItem({ ...item })
  }

  const updateSectionState = (sectionKey: number, section: ISection): void => {
    item.sections[sectionKey] = section
    setItem({ ...item })
  }

  const labels = {
    section: trans('Seção '),
    question: trans('Pergunta '),
    criterion: trans('Critério '),
    newSection: trans('Nova Seção'),
    newQuestion: trans('Nova Pergunta'),
    newCriterion: trans('Novo Critério')
  }

  const sectionValue = (sectionKey: number): number => {
    let value = sectionKey + 1
    item.sections.every((element) => {
      if (element === item.sections[sectionKey]) return false
      if (element.isDeleted) value--
      return true
    })
    return value
  }

  const fieldValue = (section: ISection, fieldKey: number): number => {
    let value = fieldKey + 1
    section.fields?.every((element) => {
      if (element === section.fields![fieldKey]) return false
      if (element.isDeleted) value--
      return true
    })
    return value
  }

  const handleClickSection = async () => {
    if (item.sections === undefined) {
      item.sections = []
    }

    item.sections.push({
      id: `${String(uuidv4())}_idScreen`,
      name: '',
      path: '',
      isDeleted: false,
      fields: []
    })
    setItem({ ...item })
    updateState(item)
  }

  const handleCopySection = async (section: ISection): Promise<void> => {
    const newSection = duplicateSection(section)
    // @ts-error
    item.sections?.push(newSection)
    setItem({ ...item })
    updateState(item)
  }

  const scrollOnAddSection = (): void => {
    const sectionsCount = item.sections.length - 1

    const element = document.querySelector(
      `[data-rbd-draggable-context-id='${inApplicationForm}'][data-rbd-draggable-id='sections[${sectionsCount}]']`
    )

    const adjustPosition = element!.getBoundingClientRect().top + window.pageYOffset + ADJUST_TOP
    window.scrollTo({ top: adjustPosition, behavior: 'smooth' })
  }

  const handleClickQuestion = async (section: ISection): Promise<void> => {
    if (section.fields === undefined) {
      section.fields = []
    }

    section.fields.push({
      id: `${String(uuidv4())}_idScreen`,
      type: 'dynamic',
      name: '',
      value: '',
      questionType: defaultQuestionType,
      availableQuestionTypes: availableQuestionTypes,
      size: 1000,
      isCriterion: !isApplicationForm,
      gradeInfo: '',
      description: '',
      isDeleted: false,
      path: '',
      options: [],
      validations: []
    })
    setItem({ ...item })
    updateState(item)
  }

  const handleCopyQuestion = async (field: IField, section: ISection, sectionKey: number): Promise<void> => {
    const newField = duplicateField(field)
    section.fields?.push(newField)
    item.sections[sectionKey] = section
    setItem({ ...item })
    updateState(item)
  }

  const scrollAddQuestion = (section: ISection): void => {
    const sectionPosition = item.sections.indexOf(section)
    const fieldsCount = Number(section.fields?.length) - 1

    const element = document.querySelector(
      `[data-rbd-draggable-context-id="${inApplicationForm}"][data-rbd-draggable-id="sections[${sectionPosition}].fields[${fieldsCount}]"]`
    )

    const adjustPosition = element!.getBoundingClientRect().top + window.pageYOffset + ADJUST_TOP
    window.scrollTo({ top: adjustPosition, behavior: 'smooth' })
  }

  const getItemStyle = (isDragging, draggableStyle): any => ({
    ...draggableStyle,
    zIndex: isDragging ? 3 : undefined
  })

  return (
    <div key={sectionIdentifier}>
      <DragDropContext onDragEnd={onDragEnded}>
        <Droppable droppableId={'sections'} type={`section-${sectionIdentifier}`} direction={'vertical'}>
          {(stepProvided) => (
            <div ref={stepProvided.innerRef} {...stepProvided.droppableProps}>
              {item.sections.map((section, sectionKey) => {
                const fieldType = isApplicationForm ? 'Question' : 'Criterion'
                const sectionNumber = sectionValue(sectionKey)
                const isFirstSection = sectionKey === 0
                const sectionId = `${String(uuidv4())}_idScreen`
                const sectionValidations = [
                  {
                    type: 'required',
                    stage: 'launch'
                  },
                  {
                    type: 'maxLength',
                    param: 1000,
                    stage: 'save'
                  },
                  {
                    type: 'letterRequired',
                    stage: 'all'
                  }
                ]
                if (isApplicationForm) {
                  sectionValidations.push({
                    type: `requiredQuestion-${sectionId} section`,
                    stage: 'all'
                  })
                }
                if (isEvaluationForm) {
                  sectionValidations.push({
                    type: `requiredField-${sectionId} section`,
                    stage: 'all'
                  })
                }
                if (section.isDeleted) {
                  return false
                }
                return (
                  <Draggable draggableId={`sections[${sectionKey}]`} key={`sections[${sectionKey}]`} index={sectionKey} >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        key={section.id}
                      >
                        <div key={sectionKey} style={{ margin: '0px 0px 25px 10px' }}>
                          <div>
                            <h6>
                              {labels.section}
                              {sectionNumber}
                              <div className="d-inline" data-testid={`challenge-${sectionIdentifier}-section-${sectionKey}-delete-button`}>
                                {((!isFirstSection || !isApplicationForm) && !disabled) &&
                                  <LightButton
                                    className="text-primary"
                                    style={{ margin: '0px 0px 0px 10px' }}
                                    onClick={() => {
                                      section.isDeleted = true
                                      item.sections[sectionKey].isDeleted = true
                                      setItem({ ...item })
                                      updateState(item)
                                    }}
                                    data-cy={`${currentForm}Section${sectionNumber}DeleteButton`}
                                  >
                                    {<FontAwesomeIcon icon={['fas', 'trash']} />}
                                  </LightButton>}
                              </div>
                              {(!disabled) &&
                                <LightButton
                                  className="text-primary"
                                  style={{ margin: '0px 0px 0px 10px' }}
                                  onClick={() => {
                                    handleCopySection(section).then(scrollOnAddSection)
                                  }}
                                  data-cy={`${currentForm}Section${sectionNumber}CopyButton`}
                                >
                                  {<FontAwesomeIcon icon={['fas', 'copy']} />}
                                </LightButton>}
                            </h6>
                          </div>
                          <div style={{ margin: '0px 0px 0px 10px' }}>
                            <div>
                              {BuildField(
                                {
                                  id: section.id,
                                  type: 'text',
                                  value: section.name,
                                  label: 'Nome da Seção',
                                  namePlaceholder: section.namePlaceholder,
                                  isDeleted: false,
                                  isRequired: true,
                                  description: 'Campo Obrigatório',
                                  maxLength: 140,
                                  dataCy: `${currentForm}Section${sectionNumber}NameInput`,
                                  path: `${sectionIdentifier}.sections[${sectionKey}]`,
                                  updateFieldState: (field: IField) => {
                                    item.sections[sectionKey].name = field.value
                                    updateSectionState(sectionKey, item.sections[sectionKey])
                                    updateState(item)
                                  },
                                  validations: sectionValidations,
                                  disabled: disabled
                                },
                                section.id
                              )}
                            </div>
                            <Droppable droppableId={`sections[${sectionKey}].fields`} type={'section-field'}>
                              {(fieldDroppableProvided) => (
                                <div
                                  ref={fieldDroppableProvided.innerRef}
                                  {...fieldDroppableProvided.droppableProps}
                                  key={sectionKey}
                                >
                                  {section.fields?.map((field: IField | IDynamicField, fieldKey) => {
                                    const fieldNumber = fieldValue(section, fieldKey)

                                    if (field.isDeleted) {
                                      return <></>
                                    }

                                    const isFirstQuestion = fieldKey === 0
                                    const questionValidations = [
                                      { type: 'required' },
                                      {
                                        type: 'maxLength',
                                        param: 1000
                                      }
                                    ]
                                    if (isFirstSection && isFirstQuestion && isApplicationForm) {
                                      questionValidations.push({
                                        type: `requiredQuestion-${sectionId} question`
                                      })
                                    }
                                    return (
                                      <Draggable
                                        draggableId={`sections[${sectionKey}].fields[${fieldKey}]`}
                                        key={`sections[${sectionKey}].fields[${fieldKey}]`}
                                        index={fieldKey}
                                      >
                                        {(fieldDraggableProvided, fieldDraggableSnapshot) => (
                                          <div
                                            ref={fieldDraggableProvided.innerRef}
                                            {...fieldDraggableProvided.draggableProps}
                                            {...fieldDraggableProvided.dragHandleProps}
                                            key={field.id}
                                            style={getItemStyle(fieldDraggableSnapshot.isDragging, fieldDraggableProvided.draggableProps.style)}
                                          >
                                            <div style={{ margin: '10px 0px 0px 10px' }}>
                                              <div>
                                                <strong>
                                                  {`${isApplicationForm ? labels.question : labels.criterion}${fieldNumber}`}
                                                  <div className="d-inline" data-testid={`challenge-${sectionIdentifier}-section-${sectionKey}-field-${fieldKey}-delete-button`}>
                                                    {((sectionKey !== 0 || fieldKey !== 0 || !isApplicationForm) && !disabled) && < LightButton
                                                      className="text-primary"
                                                      style={{ margin: '0px 0px 0px 10px' }}
                                                      onClick={() => {
                                                        field.isDeleted = true
                                                        // @ts-expect-error
                                                        item.sections[sectionKey].fields[fieldKey] = field
                                                        updateFieldState(sectionKey, fieldKey, field)
                                                        updateState(item)
                                                      }}
                                                      data-cy={`section${sectionNumber}${fieldType}${fieldNumber}DeleteButton`}
                                                    >
                                                      <FontAwesomeIcon icon={['fas', 'trash']} />
                                                    </LightButton>}
                                                  </div>
                                                  {(!disabled) && (<LightButton
                                                    className="text-primary"
                                                    style={{ margin: '0px 0px 0px 10px' }}
                                                    onClick={() => {
                                                      handleCopyQuestion(field, section, sectionKey).then(() =>
                                                        scrollAddQuestion(section)
                                                      )
                                                    }}
                                                    data-cy={`section${sectionNumber}${fieldType}${fieldNumber}CopyButton`}
                                                  >
                                                    {<FontAwesomeIcon icon={['fas', 'copy']} />}
                                                  </LightButton>)}
                                                </strong>
                                              </div>
                                              {BuildField(
                                                {
                                                  ...field,
                                                  availableQuestionTypes: availableQuestionTypes,
                                                  questionType: field.questionType,
                                                  namePlaceholder: field.namePlaceholder,
                                                  descriptionPlaceholder: field.descriptionPlaceholder,
                                                  fatherId: sectionKey,
                                                  dataCy: `section${sectionNumber}${fieldType}${fieldNumber}`,
                                                  sectionId: sectionId,
                                                  validations: questionValidations,
                                                  updateFieldState: (field: IField) => {
                                                    updateFieldState(sectionKey, fieldKey, field)
                                                    // @ts-expect-error
                                                    item.sections[sectionKey].fields[fieldKey] = field
                                                    updateState(item)
                                                  },
                                                  disabled: disabled
                                                },
                                                fieldKey
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  })}
                                  {fieldDroppableProvided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {(!disabled) && (<AddButton
                              style={{ marginTop: '10px' }}
                              onClick={() => {
                                handleClickQuestion(section).then(() => scrollAddQuestion(section))
                              }}
                              data-cy={`section${sectionNumber}Add${fieldType}Button`}
                            >
                              {isApplicationForm ? labels.newQuestion : labels.newCriterion}
                            </AddButton>)}
                            <hr />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {stepProvided.placeholder}
            </div>
          )}
        </Droppable >
      </DragDropContext >
      {(!disabled) && (<AddButton
        onClick={() => {
          handleClickSection().then(scrollOnAddSection)
        }}
        data-cy={`${currentForm}AddSectionButton`}
      >
        {labels.newSection}
      </AddButton>)}
    </div >
  )
}
