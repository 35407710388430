/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/indent */
import { IDynamicField } from '../../constants'
import { buildValidations, handleValidation } from '../../behaviors/validation'
import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { DescriptedLabel, Input } from 'iebt-zing-storybook'
import { AddButton, LightButton, StyledSwitch } from '../../../FormWrapper/style'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { v4 as uuidv4 } from 'uuid'
import { ErrorLabel, FormItem, StyledDropdown } from '../../style'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { trans } from 'sharedKernel/i18n'
export function BuildDynamicField(item: IDynamicField, fieldKey: number | string): any {
  const validations = buildValidations(item.validations)
  const groupName = (item.id ?? '').split('-').join('').toLocaleLowerCase()
  const questionTypeIcons = {
    text: ['fas', 'a'],
    select: ['fas', 'bars'],
    radioGroup: ['fas', 'circle-dot'],
    checkboxGroup: ['fa', 'square-check'],
    date: ['fas', 'calendar-days'],
    number: ['fas', 'hashtag'],
    upload: ['fas', 'circle-arrow-up'],
    url: ['fas', 'link']
  }
  const defaultQuestionTypeValue = item.questionType === undefined ? undefined : item.questionType
  const defaultQuestionType =
    item.availableQuestionTypes === undefined
      ? undefined
      : item.availableQuestionTypes.find((type) => type.value === defaultQuestionTypeValue)
  const defaultQuestionTypeLabel = defaultQuestionType === undefined ? '' : defaultQuestionType.label

  const isCriterion = Boolean(item.isCriterion)

  const [selectedQuestionTypeLabel, setSelectedQuestionTypeLabel] = useState(defaultQuestionTypeLabel)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showGradeInfo, setShowGradeInfo] = useState((item.gradeInfo !== ''))

  const labels = {
    options: trans('Opções'),
    option: trans('Opção '),
    newOption: trans('Nova Opção'),
    titleMessage: trans('Preencha este campo'),
    shortText: trans('Texto curto'),
    longText: trans('Texto longo'),
    questionType: trans('Tipo de pergunta'),
    requiredField: trans('Campo Obrigatório'),
    questionLabel: trans('O que gostaria de perguntar aos solucionadores?'),
    criterionLabel: trans('Digite o critério ao qual o solucionador deve ser avaliado'),
    description: trans('Descrição'),
    size: trans('Tamanho'),
    willQuestionBeRequired: trans('A pergunta será obrigatória?'),
    willQuestionBeHidden: trans('A pergunta será oculta para o avaliador?'),
    weight: trans('Peso'),
    addGradeInformation: trans('Adicionar escala de notas'),
    removeGradeInformation: trans('Remover escala de notas'),
    gradeInfoLabel: trans('Descreva a escala de notas'),
    gradeInformationPlaceholder: trans('Descreva as informações relevantes da nota para o avaliador')
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function optionValue(item: IDynamicField, optionKey: number) {
    let value = optionKey + 1
    item.options?.every((element) => {
      if (element === item.options![optionKey]) return false
      if (element.isDeleted) value--
      return true
    })
    return value
  }
  return (
    <FormItem key={fieldKey}>
      <div className="d-flex justify-content-between align-items-baseline">
        <div className="w-100" style={{ paddingRight: !isCriterion ? '1.5rem' : '0px' }}>
          <div className={`requiredField-${item.sectionId}-field`}>
            <Input
              id={`${groupName}-question`}
              className={`validate letterRequired required maxLength-1000 save.maxLength-1000 minLength-3 validateGroup-${groupName} dynamicField question`}
              label={isCriterion ? labels.criterionLabel : labels.questionLabel}
              value={item.name}
              maxLength={1000}
              placeholder={item.namePlaceholder}
              isRequired={true}
              description={labels.requiredField}
              title={labels.titleMessage}
              onChange={() => {
                // eslint-disable-next-line no-restricted-globals
                const element = event?.target as HTMLInputElement
                item.name = element.value
                // @ts-expect-error
                item.updateFieldState(item)
                handleValidation(element, 'save')
              }}
              data-cy={`${item.dataCy}Input`}
              disabled={item.disabled}
            />
            <ErrorLabel className={`errors-${groupName}-question`}></ErrorLabel>
          </div>
        </div>
        {!isCriterion && (
          <div className="mb-2">
            <DescriptedLabel boldText={true} htmlFor={`${groupName}-selector`}>
              {labels.questionType}
            </DescriptedLabel>
            <StyledDropdown
              show={showDropdown}
              onClick={() => setShowDropdown(!showDropdown)}
              onToggle={() => setShowDropdown(!showDropdown)}
              id={`${groupName}-selector`}
              onSelect={(eventKey, event) => {
                item.questionType = eventKey === null ? undefined : eventKey
                // @ts-expect-error
                item.updateFieldState(item)
                const selectedQuestionType = item.availableQuestionTypes?.find((type) => type.value === eventKey)
                const selectedQuestionTypeLabel = selectedQuestionType === undefined ? '' : selectedQuestionType.label
                setSelectedQuestionTypeLabel(selectedQuestionTypeLabel)
                const description = document.getElementsByClassName(
                  `validateGroup-${groupName} description`
                )[0] as HTMLInputElement
                handleValidation(description, 'save')
                handleValidation(event.target.parentElement.parentElement.firstChild, 'save')
              }}
            >
              <Dropdown.Toggle
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '12rem'
                }}
                id="dropdown-flags"
                className={`validate required validateGroup-${groupName} dynamicField selector`}
                value={item.questionType}
                data-cy={`${item.dataCy}Type`}
                disabled={item.disabled}
              >
                {item.questionType !== undefined && (
                  <FontAwesomeIcon
                    className="pr-2"
                    icon={questionTypeIcons[item.questionType.replace(/-([a-z])/g, (x, up) => up.toUpperCase())]}
                  />
                )}
                {selectedQuestionTypeLabel}
                <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: '10px', color: '#212529' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {item.availableQuestionTypes?.map((option, optionKey) => {
                  const optionIcon = questionTypeIcons[option.value.replace(/-([a-z])/g, (x, up) => up.toUpperCase())]
                  return (
                    <Dropdown.Item key={optionKey} eventKey={option.value} disabled={item.disabled}>
                      <FontAwesomeIcon className="pr-2" icon={optionIcon} />
                      {option.label}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </StyledDropdown>
            <ErrorLabel className={`errors-${groupName}-selector`}></ErrorLabel>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-baseline">
        <div className="w-100">
          <Input
            id={`${groupName}-description`}
            label={labels.description}
            value={item.description}
            maxLength={1000}
            placeholder={item.descriptionPlaceholder}
            onChange={() => {
              // eslint-disable-next-line no-restricted-globals
              const element = event?.target as HTMLInputElement
              item.description = element.value
              // @ts-expect-error
              item.updateFieldState(item)
            }}
            className={`validate maxLength-1000 save.maxLength-1000 validateGroup-${groupName} dynamicField description`}
            data-cy={`${item.dataCy}Description`}
            disabled={item.disabled}
          />
          <ErrorLabel className={`errors-${groupName}-description`}></ErrorLabel>
        </div>
        {item.questionType === 'text' && (
          <div className="mb-2" style={{ paddingLeft: '1.5rem' }}>
            <DescriptedLabel boldText={true} htmlFor={`${groupName}-size`}>
              {labels.size}
            </DescriptedLabel>
            <Form.Select
              style={{ width: 'max-content' }}
              id={`${groupName}-size`}
              className={`${validations} validateGroup-${groupName} dynamicField size`}
              defaultValue={String(item.size)}
              onChange={() => {
                // eslint-disable-next-line no-restricted-globals
                const element = event?.target as HTMLInputElement
                item.size = Number(element.value)
                // @ts-expect-error
                item.updateFieldState(item)
                handleValidation(element, 'save')
              }}
              data-cy={`${item.dataCy}Size`}
              disabled={item.disabled}
            >
              <option value={'1000'}>{labels.shortText}</option>
              <option value={'5000'}>{labels.longText}</option>
            </Form.Select>
            <ErrorLabel className={`errors-${groupName}-size`}></ErrorLabel>
          </div>
        )}
      </div>
      {!isCriterion && (
        <div className="d-flex justify-content-start align-items-baseline pb-3">
          <StyledSwitch style={{ paddingRight: '3rem' }}>
            <Input
              code="switch"
              className={`requiredQuestion-${item.sectionId} switch`}
              checked={item.isRequired}
              type="field"
              label={labels.willQuestionBeRequired}
              name="isRequired"
              onChange={() => {
                const element = event?.target as HTMLInputElement
                item.isRequired = element.checked

                // @ts-expect-error
                item.updateFieldState(item)
                handleValidation(element, 'save')
                const sectionElement = document.getElementsByClassName(`requiredQuestion-${item.sectionId} section`)[0]
                handleValidation(element, 'save')
                handleValidation(sectionElement, 'save')
              }}
              data-cy={`${item.dataCy}Switch`}
              disabled={item.disabled}
            />
          </StyledSwitch>
          <StyledSwitch>
            <Input
              code="switch"
              className={`hiddenQuestion-${item.sectionId} switch`}
              checked={item.isHidden}
              type="field"
              label={labels.willQuestionBeHidden}
              name="isHidden"
              onChange={() => {
                const element = event?.target as HTMLInputElement
                item.isHidden = element.checked

                // @ts-expect-error
                item.updateFieldState(item)
              }}
              data-cy={`${item.dataCy}HiddenSwitch`}
              disabled={item.disabled}
            />
          </StyledSwitch>
        </div>
      )}
      {(item.questionType === 'select' ||
        item.questionType === 'radio-group' ||
        item.questionType === 'checkbox-group') && (
          <div>
            <DescriptedLabel boldText={true}>{labels.options}</DescriptedLabel>
            <div>
              <Droppable
                droppableId={`sections[${item.fatherId ?? 0}].fields[${fieldKey}].options`}
                type={'section-field-option'}
              >
                {(stepProvided) => (
                  <div ref={stepProvided.innerRef} {...stepProvided.droppableProps}>
                    {item.options?.map((option, optionKey) => {
                      const optionNumber = optionValue(item, optionKey)
                      if (option.isDeleted) {
                        return true
                      }
                      return (
                        <Draggable
                          draggableId={`sections[${item.fatherId ?? 0}].fields[${fieldKey}].options[${optionKey}]`}
                          key={`sections[${item.fatherId ?? 0}].fields[${fieldKey}].options[${optionKey}]`}
                          index={optionKey}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={option.id}
                            >
                              <div style={{ margin: '0px 0px 10px 30px' }}>
                                <Row key={optionKey} className="px-0 mx-0 mb-1">
                                  <Col className="flex-grow-1 px-0 mx-0">
                                    <Input
                                      id={`${groupName}-option-${optionKey}`}
                                      label={`${labels.option}${optionNumber}`}
                                      className={`validate required maxLength-1000 save.maxLength-1000 minLength-1 validateGroup-${groupName} dynamicField option`}
                                      value={option.value}
                                      maxLength={1000}
                                      onChange={() => {
                                        // eslint-disable-next-line no-restricted-globals
                                        const element = event?.target as HTMLInputElement
                                        option.value = element.value
                                        // @ts-expect-error
                                        item.updateFieldState(item)
                                        handleValidation(element, 'save')
                                      }}
                                      data-cy={`${item.dataCy}Option${optionNumber}`}
                                      disabled={item.disabled}
                                    />
                                  </Col>
                                  {!(item.disabled ?? false) && (
                                    <Col className="flex-grow-0 d-flex align-items-center pe-0 mt-3">
                                      <LightButton
                                        className="text-primary"
                                        onClick={() => {
                                          option.isDeleted = true
                                          // @ts-expect-error
                                          item.updateFieldState(item)
                                        }}
                                        data-cy={`${item.dataCy}Option${optionNumber}DeleteButton`}
                                      >
                                        {<FontAwesomeIcon icon={['fas', 'trash']} />}
                                      </LightButton>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <ErrorLabel className={`errors-${groupName}-option-${optionKey}`}></ErrorLabel>
                                </Row>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  </div>
                )}
              </Droppable>
              {!(item.disabled ?? false) && (
                <AddButton
                  onClick={() => {
                    if (item.options === undefined) {
                      item.options = []
                    }
                    item.options.push({
                      id: `${String(uuidv4())}_idScreen`,
                      path: '',
                      value: '',
                      isDeleted: false
                    })

                    // @ts-expect-error
                    item.updateFieldState(item)
                    const description = document.getElementsByClassName(
                      `validateGroup-${groupName} description`
                    )[0] as HTMLInputElement
                    handleValidation(description, 'save')
                  }}
                  data-cy={`${item.dataCy}AddOptionButton`}
                >
                  {labels.newOption}
                </AddButton>
              )}
            </div>
            <ErrorLabel className={`errors-${groupName}-option`}></ErrorLabel>
          </div>
        )}
      {isCriterion && (
          <div className="d-flex gap-1 mb-1">
            <div style={{ width: '6vw' }}>
              <Input
                id={`${groupName}-weight`}
                className={`validate required validateGroup-${groupName} dynamicField weight`}
                value={item.weight}
                type='number'
                min={1}
                label={labels.weight}
                max={100}
                onChange={() => {
                  // eslint-disable-next-line no-restricted-globals
                  const element = event?.target as HTMLInputElement
                  const newValue = Math.min(100, Math.max(1, Number(element.value)))
                  item.weight = newValue
                  // @ts-expect-error
                  item.updateFieldState(item)
                  handleValidation(element, 'save')
                }}
                data-cy={`${item.dataCy}Weight`}
                disabled={item.disabled}
                step={1}
              />
            </div>

            <ErrorLabel className={`errors-${groupName}-weight`}></ErrorLabel>

          {(!(item.disabled ?? false)) && (
            <div style={{ display: 'inline-block', marginTop: '2.2rem' }}>
            <AddButton
              onClick={async () => {
                setShowGradeInfo(!showGradeInfo)
                if (showGradeInfo) { // A logica aqui pode parecer um pouco estranho, mas tem ser desse jeito para funcionar. A logica ficou meio invertida.
                  item.gradeInfo = ''
                  // @ts-expect-error
                  item.updateFieldState(item)
                }
              }}
              data-cy={`section${item.sectionId}Add${item.type}Button`}
            >
              {showGradeInfo ? labels.removeGradeInformation : labels.addGradeInformation}
            </AddButton></div>)}
        </div>
      )}
      {isCriterion && (
      <div>
        <small style={{ color: '#8C8C8C' }}>Digite um valor de 1 a 100</small>
      </div>
      )}
      {(showGradeInfo && isCriterion) && (
        <div className='mb-4'>
          <Input
            code="textarea"
            label={labels.gradeInfoLabel}
            name="gradeInfo"
            onChange={() => {
              const element = event?.target as HTMLInputElement
              item.gradeInfo = element.value
              // @ts-expect-error
              item.updateFieldState(item)
            }}
            value={item.gradeInfo}
            placeholder={labels.gradeInformationPlaceholder}
          />
        </div>
      )}
    </FormItem>
  )
}
