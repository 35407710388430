import axios from 'axios'
import _ from 'lodash'

import { IHotsiteContactForm } from '../store'
import {
  GOOGLE_FORM_CONTACT_ACTION,
  GOOGLE_FORM_NAME_ID,
  GOOGLE_FORM_NICHE_ID,
  GOOGLE_FORM_COMPANY_NAME_ID,
  GOOGLE_FORM_ROLE_ID,
  GOOGLE_FORM_EMAIL_ID,
  GOOGLE_FORM_PHONE_ID,
  GOOGLE_FORM_CHECKBOX_GROUP_ID,
  GOOGLE_FORM_ACCEPT_TERMS_ID,
  GOOGLE_FORM_ANOTHER_ROLE_ID,
  GOOGLE_FORM_SUBJECT_ID
} from './GoogleForm'

export const makeGoogleFormContactRequest = (contactForm: IHotsiteContactForm): void => {
  axios.post(fillContactFormUrl(contactForm))
}

const fillContactFormUrl = (contactForm: IHotsiteContactForm): string => {
  const url = new URL(GOOGLE_FORM_CONTACT_ACTION)
  url.searchParams.set(GOOGLE_FORM_NAME_ID, contactForm.form.name.value)
  url.searchParams.set(GOOGLE_FORM_NICHE_ID, contactForm.form.niche.value)
  url.searchParams.set(GOOGLE_FORM_COMPANY_NAME_ID, contactForm.form.companyName.value)
  url.searchParams.set(GOOGLE_FORM_ROLE_ID, contactForm.form.role.value)
  url.searchParams.set(GOOGLE_FORM_ANOTHER_ROLE_ID, '')
  url.searchParams.set(GOOGLE_FORM_EMAIL_ID, contactForm.form.email.value)
  url.searchParams.set(GOOGLE_FORM_PHONE_ID, contactForm.form.phone.value)
  url.searchParams.set(GOOGLE_FORM_SUBJECT_ID, contactForm.form.subject.value)
  if (contactForm.whatsappContact) {
    url.searchParams.set(GOOGLE_FORM_CHECKBOX_GROUP_ID, 'Whatsapp')
  }
  if (contactForm.phoneContact) {
    url.searchParams.set(GOOGLE_FORM_CHECKBOX_GROUP_ID, 'Ligação')
  }
  if (contactForm.emailContact) {
    url.searchParams.set(GOOGLE_FORM_CHECKBOX_GROUP_ID, 'E-mail')
  }
  if (contactForm.acceptUserTerms) {
    url.searchParams.set(GOOGLE_FORM_ACCEPT_TERMS_ID, 'Concordo com os termos de uso')
  }
  return url.toString()
}
