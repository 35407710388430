import { Loading } from 'components/loading'
import React, { useEffect, useRef, useState } from 'react'
import { api, GetFormat } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { Col, Row } from 'react-bootstrap'
import { BaseTag, Input } from 'iebt-zing-storybook'
import { SwiperSlide } from 'swiper/react'
import { Navigation, Keyboard } from 'swiper'
import {
  LikertScaleRange,
  Questions,
  QuestionsContainer,
  SectionTitleHeader,
  SectionTitleText,
  SectionTitleWrapper
} from './styles'
import { EvaluationData } from './contracts'

export interface SolutionEvaluationProps {
  challengeId: string
  solutionId: string
  evaluatorId: string
}

export const EvaluationModal = ({ challengeId, solutionId, evaluatorId }: SolutionEvaluationProps): JSX.Element => {
  const getRequest: GetFormat = {
    url: `/v1/hub/company/evaluation/challenge/${challengeId}/solution/${solutionId}/evaluator/${evaluatorId}`
  }

  const [actualSlide, setActualSlide] = useState(0)
  const [evaluationData, setSolutionData] = useState<EvaluationData>()
  const scaleRefs = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    api.get(getRequest.url).then((response) => {
      setSolutionData(response.data)
    })
  }, [])

  const labels = {
    actualGrade: trans('Nota atual'),
    comments: trans('Comentários'),
    feedbackSectionTitile: trans('Feedback geral'),
    feedback: trans('Feedback')
  }

  if (evaluationData == null) {
    return <Loading />
  }

  return (
    <Row>
      <Row className="pb-3">
        <Col>{labels.actualGrade}</Col>
        <Col>
          <BaseTag tagContent={evaluationData.data.points.toFixed(2)} variant="primary-invert" />
        </Col>
      </Row>
      <Row>
        <SectionTitleWrapper
          keyboard={{
            enabled: true
          }}
          onSlideChange={(swiper) => {
            setActualSlide(swiper.activeIndex)
          }}
          slidesPerView={1}
          modules={[Navigation, Keyboard]}
          navigation={true}
          allowSlideNext={true}
          mousewheel={false}
          grabCursor={false}
        >
          {evaluationData.data.sections.map((section, sectionIndex) => {
            return (
              <SwiperSlide key={`swiperSlide.${String(sectionIndex)}`}>
                <SectionTitleHeader>
                  <SectionTitleText>{section.name}</SectionTitleText>
                </SectionTitleHeader>
              </SwiperSlide>
            )
          })}
          <SwiperSlide key={`swiperSlide.${String(evaluationData.data.sections.length)}`}>
            <SectionTitleHeader>
              <SectionTitleText>{labels.feedbackSectionTitile}</SectionTitleText>
            </SectionTitleHeader>
          </SwiperSlide>
        </SectionTitleWrapper>
      </Row>
      <QuestionsContainer>
        {evaluationData.data.sections.length !== actualSlide
          ? (
            <>
              {evaluationData.data.sections[actualSlide].answers.map((answer, answerIndex) => {
                return (
                  <Row key={answerIndex}>
                    <Questions className="my-auto" sm={5}>
                      {`${Number(answer.order) + 1}. `}
                      {answer.question}
                    </Questions>
                    <Col className="my-auto">
                      <Row className="d-flex container-fluid mb-3">
                        <Col sm={9}>
                          <LikertScaleRange>
                            <input
                              key={answerIndex}
                              list="markers"
                              className={answer.value === null ? 'inactive' : 'active'}
                              type="range"
                              min="0"
                              max={5}
                              step="1"
                              value={answer.value}
                              ref={(element) => {
                                scaleRefs.current[answerIndex] = element as HTMLInputElement
                              }}
                              disabled={true}
                            />
                            <datalist id="markers">
                              {[...Array(6).keys()].map((value, valueIndex) => {
                                return <option key={`likertScaleOption.${valueIndex}`} value={value} />
                              })}
                            </datalist>
                          </LikertScaleRange>
                        </Col>
                        <Col sm={1}>{answer.value}</Col>
                      </Row>
                    </Col>
                  </Row>
                )
              })}
              <Row>
                <Input
                  code="textarea"
                  label={labels.feedback}
                  name="comments"
                  value={evaluationData.data.sections[actualSlide].comments ?? ''}
                  disabled={true}
                />
              </Row>
            </>
            )
          : (
            <Input
              code="textarea"
              label={labels.feedback}
              name="comments"
              value={evaluationData.data.comments ?? ''}
              disabled={true}
            />
            )}
      </QuestionsContainer>
    </Row>
  )
}
