import { IField } from '../../constants'
import React, { useEffect, useState } from 'react'
import { ErrorLabel, FormItem } from '../../style'
import { Form } from 'react-bootstrap'
import { DescriptedLabel } from 'iebt-zing-storybook'

export function BuildCheckbox(item: IField, itemKey): JSX.Element {
  const [checked, setChecked] = useState<boolean>(item.value ?? false)

  useEffect(() => {
    if (item.value == null) {
      setChecked(true)
      item.value = true
      item.updateFieldState?.(item)
    }
  }, [item])

  const handleCheckboxChange = (newValue: boolean): void => {
    setChecked(newValue)
    item.value = newValue
    item.updateFieldState?.(item)
  }

  return (
    <FormItem key={itemKey}>
      <DescriptedLabel description={item.description} boldText={item.isRequired ?? false} htmlFor={item.id}>
        {item.label}
      </DescriptedLabel>
      <Form.Check
        onChange={() => handleCheckboxChange(true)}
        disabled={item.disabled}
        id={item.id}
        checked={checked}
        label="Sim, o desafio pode ser público e acessível a todos."
      />
      <Form.Check
        onChange={() => handleCheckboxChange(false)}
        disabled={item.disabled}
        id={item.id}
        checked={!checked}
        label="Não, apenas usuários logados podem visualizar e se inscrever no desafio."
      />
      <ErrorLabel className={`errors-${item.id}`} />
    </FormItem>
  )
}
