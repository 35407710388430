import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { trans } from 'sharedKernel/i18n'
import { store } from 'store'
import { UserTerms } from 'components/UserTerms'
import { IHotsiteEvaluatorRegisterForm, IHotsiteRegisterForm, updateEvaluatorRegisterForm, updateRegisterForm } from '../store'
import { handleFormValidity } from './validations'
import { postProvider, PostFormat } from 'sharedKernel/api'
import { createRegisterRequest } from './behavior'
import { showToast } from 'components/toast'
import { ROUTES } from 'sharedKernel/constants'
import { useNavigate } from 'react-router'
import { LoginButton } from './Login'

export interface RegisterActionsProps {
  registerUrl: string
  userType: string
  registerFormState: IHotsiteRegisterForm | IHotsiteEvaluatorRegisterForm
  registerType: string
}

export const RegisterActions = ({ registerUrl, userType, registerFormState, registerType }: RegisterActionsProps): JSX.Element => {
  const { mutate: subscribeStartup } = postProvider()
  const navigate = useNavigate()

  const labels = {
    subscribe: trans('Cadastrar'),
    login: trans('Login'),
    alreadyHaveAccount: trans('Já tem cadastro? Faça o login.'),
    registerSuccess: trans('Cadastro realizado com sucesso!')
  }

  const handleChange = (value, key): void => {
    registerFormState[key] = value

    if (registerType === 'Resolver') {
      store.dispatch(updateRegisterForm(registerFormState))
    } else {
      store.dispatch(updateEvaluatorRegisterForm(registerFormState))
    }
  }

  const handleSave = (): void => {
    const registerPost: PostFormat = createRegisterRequest(registerFormState.form, registerUrl, registerFormState.acceptUserTerms)

    subscribeStartup(registerPost, {
      onSuccess: () => {
        showToast('success', labels.registerSuccess)
        navigate(ROUTES.USER_AUTH.LOGIN)
      },
      onError: (error) => {
        showToast('error', error.response.data.message)
      }
    })
  }

  return (
    <Col>
      <Row className='p-0'>
        <UserTerms
          userType={userType}
          checked={registerFormState?.acceptUserTerms}
          onCheck={(event) => {
            handleChange(event.target.checked, 'acceptUserTerms')
            if (registerType === 'Resolver') {
              store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
            } else {
              store.dispatch(updateEvaluatorRegisterForm(handleFormValidity(registerFormState)))
            }
          }}
        />
      </Row>
      <Row className='mt-4 p-0'>
        <BaseButton onClick={() => handleSave()} disabled={!registerFormState.isFormValid} size="big" btnContent={labels.subscribe} variant="primary" />
      </Row>
    </Col>
  )
}
